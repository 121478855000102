/* eslint-disable indent */

import { FC, useEffect } from 'react';
import 'antd/dist/reset.css';
import { DatePicker, Space, ConfigProvider } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
// Импорт локалей для antd остаётся – они отвечают за переводы элементов интерфейса
import localeRu from 'antd/es/locale/ru_RU';
import localeEn from 'antd/es/locale/en_US';
import 'antd/es/date-picker/style/index';
import { useAppSelector } from 'hooks/redux';
import { useStyles } from './DateRangePickerWrapperStyles.jss';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';

// Подключаем плагин для поддержки парсинга по формату
dayjs.extend(customParseFormat);

// Устанавливаем дефолтную локаль для Dayjs
dayjs.locale('ru');

interface getLocaleProps {
	[key: string]: any;
}

const START_DATE_LIMIT = '2021-01-01';

interface DateRangePickerWrapperProps {
	value: (Dayjs | null)[] | null | undefined;
	dateError: boolean;
	disabled: boolean;
	disabledDate?: any;
	onChange: (dates: any) => void;
	showTime?: boolean;
	hideRanges?: boolean;
	typeRanges?: 'all' | 'current' | 'custom';
	borderRadius?: string;
}

const DateRangePickerWrapper: FC<DateRangePickerWrapperProps> = ({
	value,
	dateError,
	disabled,
	disabledDate,
	onChange,
	showTime,
	hideRanges,
	typeRanges = 'all',
	borderRadius,
}) => {
	const { lang } = useParams<RouteParams>();

	const { RangePicker } = DatePicker;
	const getLocale: getLocaleProps = {
		ru: localeRu,
		en: localeEn,
	};

	const classes = useStyles({ showTime, borderRadius });

	const dateFormat = showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
	const dateFormatList = [dateFormat, 'DD/MM/YYYY'];

	// Функция для ограничения выбора дат: используем dayjs
	const disabledDateCustom = (current: Dayjs): boolean =>
		current.isBefore(dayjs(START_DATE_LIMIT, 'YYYY-MM-DD'), 'day');

	// Функция для парсинга передаваемых дат. Если value уже содержит Dayjs-объекты,
	// возможно, здесь парсинг не нужен, но если value приходят в виде строк или Date – используйте dayjs.
	const getDates = (
		dates: (Dayjs | null)[] | null | undefined,
		shouldShowTime: boolean | undefined,
	): [Dayjs | null, Dayjs | null] => {
		const format = shouldShowTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
		if (dates) {
			if (dates[0] && dates[1]) return [dayjs(dates[0], format), dayjs(dates[1], format)];
		}
		return [null, null];
	};

	return (
		<ConfigProvider locale={getLocale[lang]}>
			<Space direction="horizontal" size={20} className={classes.wrapperRangePicker}>
				<RangePicker
					defaultValue={getDates(value, showTime)}
					value={getDates(value, showTime)}
					format={dateFormatList}
					status={dateError ? 'error' : ''}
					className={classes.rangePicker}
					disabled={disabled}
					disabledDate={disabledDate || disabledDateCustom}
					popupClassName={classes.dropdown}
					showTime={showTime ? { format: 'HH:mm' } : false}
					onChange={onChange}
				/>
			</Space>
		</ConfigProvider>
	);
};

export default DateRangePickerWrapper;
