import { PROGRESS_INTERVAL } from 'pages/Calls/const';
import { FC } from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';
import PlayIcon from 'shared/ui/icons/general/PlayIcon/PlayIcon';

interface VideoPlayerProps {
	url: string;
	pip: boolean;
	playingVideo: boolean;
	width?: string;
	height?: string;
	videoRef: any;
	className?: any;
	controls?: boolean;
	onSeek?: (seconds: number) => void;
	onProgress: (time: number) => void;
	onEnablePIP?: () => void;
	onDisablePIP?: () => void;
}

const VideoPlayer: FC<VideoPlayerProps> = ({
	url,
	pip,
	width,
	height,
	videoRef,
	className,
	playingVideo,
	onSeek,
	onProgress,
	onEnablePIP,
	onDisablePIP,
	controls = true,
}) => {
	return (
		<ReactPlayer
			url={url}
			ref={videoRef}
			pip={pip}
			width={width}
			height={height ?? '360px'}
			playing={playingVideo}
			controls={controls}
			className={className}
			progressInterval={PROGRESS_INTERVAL}
			playIcon={<PlayIcon />}
			onSeek={onSeek}
			onProgress={(state: OnProgressProps) => {
				onProgress(state.playedSeconds);
			}}
			onEnablePIP={onEnablePIP}
			onDisablePIP={onDisablePIP}
			config={{
				file: {
					attributes: {
						controlsList: 'nodownload',
					},
				},
			}}
		/>
	);
};

export default VideoPlayer;
