import { FC, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { MultiValue } from 'react-select/dist/declarations/src/types';
import { CircularProgress } from '@mui/material';
import {
	getSearchChecklistsSuggestions,
	getSearchCommentsSuggestions,
	getSearchTagsSuggestions,
} from 'store/calls/actions';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';
import { RootState } from 'store/store';
import { useDebounceCB } from 'hooks/useDebounce';
import {
	getOptionsForChecklistSearch,
	getOptionsForCommentsSearch,
	getOptionsForComplexSelect,
} from 'utils/convertDataForSearchFilters/convertData';
import {
	IMultiSelectWithSearch,
	OptionType,
	ISuggest,
	ICommentsSuggest,
	IChecklistSuggest,
} from '../multiValuesSelect';
import { getMultiSelectStyles, selectCustomStylesCreator } from '../MultiValueSelect.jss';
import {
	ComplexChecklistOption,
	ComplexCommentOption,
	ComplexTagOption,
	CustomMenuList,
	CustomMultiValueTooltip,
} from '../MultiValueSelect.components';

const searchConfig: Record<string, any> = {
	search_by_tags: {
		cb: getSearchTagsSuggestions,
		option: ComplexTagOption,
	},
	search_by_checklists: {
		cb: getSearchChecklistsSuggestions,
		option: ComplexChecklistOption,
	},
	search_by_comments: {
		cb: getSearchCommentsSuggestions,
		option: ComplexCommentOption,
	},
};

// TODO: разнести на 2 компонента: базовый ui и компонент с логикой для фильтров

/**
 * Селект с поиском. Результаты поиска
 * отображаются в выпадающем списке
 * Предназначен для фильтров звонков (по тегам, по чеклистам, по gpt комментариям)
 * Апи запросы захардкожены
 */
const MultiSelectWithSearchCriteria: FC<IMultiSelectWithSearch> = ({
	value,
	placeholder,
	height,
	valueHandler,
	conditionItemId,
	criteriaKey,
	withQuestions,
	onlyChecklistId,
	borderColor,
}) => {
	const dispatch = useAppDispatch();
	const { language } = useAppSelector((state: RootState) => state.lang);

	// Используем значение по умолчанию для conditionItemId, если он не определен
	const effectiveConditionItemId = conditionItemId !== undefined ? conditionItemId : 0;

	const [suggestions, setSuggestions] = useState<Record<number, OptionType[]>>({});
	const [selectedValues, setSelectedValues] = useState<Record<number, OptionType[]>>({
		[effectiveConditionItemId]: value,
	});

	const styles: any = selectCustomStylesCreator({ height, menuWidth: '450px', borderColor });

	const { selectListWrapper } = getMultiSelectStyles();

	const filterDataWithQuestionsId = (data: IChecklistSuggest[]) =>
		data.filter((cheklist) => cheklist.checklist_id && cheklist.question_id && !cheklist.answer_text);

	const filterDataOnlyChecklistId = (data: IChecklistSuggest[]) =>
		data.filter((cheklist) => cheklist.checklist_id && !cheklist.question_id && !cheklist.answer_text);

	const getData = (
		data: ISuggest[] | ICommentsSuggest[] | IChecklistSuggest[],
		withQuestionsFlag: boolean | undefined,
		onlyChecklistIdFlag: boolean | undefined,
	) => {
		if (withQuestionsFlag) {
			return filterDataWithQuestionsId(data as IChecklistSuggest[]);
		}
		if (onlyChecklistIdFlag) {
			return filterDataOnlyChecklistId(data as IChecklistSuggest[]);
		}
		return data;
	};
	const getSuggestions = async (inputValue: string) => {
		const response = await dispatch(searchConfig[criteriaKey].cb(inputValue));
		const data = getData(response.payload.data, withQuestions, onlyChecklistId);

		let options: OptionType[];
		switch (criteriaKey) {
			case 'search_by_checklists':
				options = getOptionsForChecklistSearch(data as IChecklistSuggest[], inputValue);
				break;
			case 'search_by_comments':
				options = getOptionsForCommentsSearch(data as ICommentsSuggest[], inputValue);
				break;
			case 'search_by_tags':
			default:
				options = getOptionsForComplexSelect(data as ISuggest[], inputValue);
				break;
		}

		setSuggestions((prevSuggestions) => ({
			...prevSuggestions,
			[effectiveConditionItemId]: options,
		}));

		return options;
	};

	const onLoadSuggestions = useDebounceCB((inputValue: string, callback: any) => {
		getSuggestions(inputValue).then((options) => callback(options));
	}, 1000);

	const handleOptionSelect = (newValues: MultiValue<OptionType>) => {
		setSelectedValues((prevValues) => ({
			...prevValues,
			[effectiveConditionItemId]: newValues as OptionType[],
		}));
	};

	const loadingMessage = () => (
		<div className={selectListWrapper}>
			<CircularProgress color="secondary" />
		</div>
	);
	const noOptionsMessage = () => <div className={selectListWrapper}>{translate('noOptionsMessage', language)}</div>;

	// открытие меню со всеми возможными значениями при клике на инпут (кроме поиска по тегам)
	const isNotSearchByTags = criteriaKey !== 'search_by_tags';
	const onMenuOpenWithCondition = isNotSearchByTags
		? () => {
				getSuggestions('');
		  }
		: () => {};

	useEffect(() => {
		setSelectedValues((prevValues) => ({
			...prevValues,
			[effectiveConditionItemId]: value,
		}));
	}, [value, conditionItemId]);
	return (
		<AsyncSelect
			value={selectedValues[effectiveConditionItemId]}
			styles={styles}
			isClearable
			isMulti
			closeMenuOnSelect={false}
			hideSelectedOptions={false}
			loadOptions={onLoadSuggestions}
			defaultOptions={suggestions[effectiveConditionItemId]}
			placeholder={placeholder}
			onChange={handleOptionSelect}
			onMenuClose={() => {
				valueHandler(selectedValues[effectiveConditionItemId], undefined, conditionItemId);
				setSuggestions((prevSuggestions) => ({
					...prevSuggestions,
					[effectiveConditionItemId]: [],
				}));
			}}
			onMenuOpen={onMenuOpenWithCondition}
			loadingMessage={loadingMessage}
			noOptionsMessage={noOptionsMessage}
			openMenuOnFocus={isNotSearchByTags}
			openMenuOnClick={isNotSearchByTags}
			components={{
				DropdownIndicator: () => null,
				LoadingIndicator: () => null,
				MenuList: CustomMenuList,
				Option: searchConfig[criteriaKey].option,
				MultiValueLabel: CustomMultiValueTooltip,
			}}
		/>
	);
};

export default MultiSelectWithSearchCriteria;
