import React, { FC } from 'react';
import cn from 'classnames';
import { Typography } from '@mui/material';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { CriteriaBlockPage, CriteriasType, TemplateType } from 'store/search/search.types';
import { CriteriaTemplate, CriteriaWithConditions, Hint } from 'components/common';
import CriteriaItem from '../CriteriaItem';
import { HandlerOnChange, OnAddCondition, Page, SortedCriteriasListType } from '../CriteriasList';
import { useCriteriaStyles } from '../hooks/useCriteriaStyles';
import TooltipCustom from 'components/common/Tooltip/Tooltip';
import { IconQuestion } from 'components/Icons/IconQuestion';
import { optionsCreatorSimpleTitle } from 'utils/optionsCreator';
import { TitleWithTooltip } from 'shared/ui';

interface ICriteriaHeader {
	// доп свойства для заголовков
	blockTitle?: string;
	onTemplateSelectValueChange?: (event: any) => void;
	convertedTemplate?: {
		value: any;
		label: string;
	}[];
	currentTemplate?: TemplateType | null;
	allTemplates?: TemplateType[];
	onClearTemplateClick?: () => void;
}
interface ICriteriaMultiList extends ICriteriaHeader {
	activeCriterias: CriteriasType[];
	allCriterias: CriteriasType[];
	sortedCriterias: SortedCriteriasListType[];
	block?: CriteriaBlockPage;
	handlerOnChange?: HandlerOnChange;
	isHandlerFunction?: boolean;
	page: Page;
	onAddCondition?: OnAddCondition;
	onDeleteCondition?: OnAddCondition;
	simpleListWithTitles?: boolean;
}

interface CriteriasTopHeaderProps extends ICriteriaHeader {
	classes: any;
	language: string;
	simpleListWithTitles: boolean | undefined;
}

const CriteriasTopHeader: FC<CriteriasTopHeaderProps> = ({
	classes,
	language,
	blockTitle,
	currentTemplate,
	convertedTemplate,
	allTemplates,
	simpleListWithTitles,
	onTemplateSelectValueChange,
	onClearTemplateClick,
}) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div className={classes.searchTitleTop} style={{ width: '60%' }}>
				<div className={classes.searchTitleLeft}>
					<Typography className={classes.searchTitleTitle} variant="subtitle1">
						{blockTitle || translate('fragmentSearch', language)}
					</Typography>
					<TooltipCustom
						background="dark"
						title={translate('tooltipFragments', language)}
						placement="right"
						followCursor={false}
					>
						<div className={classes.questionWrapper}>
							<IconQuestion />
							<div className={classes.questionTitle}>{translate('howToSearch', language)}</div>
						</div>
					</TooltipCustom>
					{currentTemplate !== undefined && allTemplates !== undefined && (
						<>
							{/* шаблоны */}
							{!simpleListWithTitles && (
								<CriteriaTemplate
									height="400px"
									name="templatesCalls"
									title={translate('savedSearchTemp', language)}
									handleValueChange={onTemplateSelectValueChange as (event: any) => void}
									options={
										convertedTemplate as {
											value: any;
											label: string;
										}[]
									}
									currentTemplate={optionsCreatorSimpleTitle(currentTemplate)}
									allTemplates={allTemplates}
									onClearTemplateClick={onClearTemplateClick}
									dataTestTd="templatesCalls"
								/>
							)}
						</>
					)}
				</div>
			</div>
			<div className={classes.searchTitleTop} style={{ width: '35%', paddingLeft: '40px' }}>
				<div className={classes.searchTitleLeft}>
					<Typography className={classes.searchTitleTitle} variant="subtitle1">
						{translate('callsParameters', language)}
					</Typography>
					<TooltipCustom
						background="dark"
						title={translate('tooltipParam', language)}
						placement="bottom"
						followCursor={false}
					>
						<div className={classes.questionWrapper}>
							<IconQuestion />
							<div className={classes.questionTitle}>{translate('whatIsIt', language)}</div>
						</div>
					</TooltipCustom>
				</div>
			</div>
		</div>
	);
};

// TODO: переписать
const CriteriaMultiList: FC<ICriteriaMultiList> = ({
	activeCriterias,
	allCriterias,
	sortedCriterias,
	block,
	handlerOnChange,
	isHandlerFunction,
	page,
	onAddCondition,
	onDeleteCondition,
	simpleListWithTitles,
	blockTitle,
	onTemplateSelectValueChange,
	convertedTemplate,
	currentTemplate,
	allTemplates,
	onClearTemplateClick,
}) => {
	const { language } = useAppSelector((state) => state.lang);
	const { classes, getBlockClassName, getBlockSearchClassName } = useCriteriaStyles();

	return (
		<div style={{ width: '100%' }}>
			{page !== 'callsFromReportCell' && page !== 'reports' && !simpleListWithTitles && (
				<CriteriasTopHeader
					classes={classes}
					language={language}
					blockTitle={blockTitle}
					currentTemplate={currentTemplate}
					convertedTemplate={convertedTemplate}
					allTemplates={allTemplates}
					simpleListWithTitles={simpleListWithTitles}
					onTemplateSelectValueChange={onTemplateSelectValueChange}
					onClearTemplateClick={onClearTemplateClick}
				/>
			)}

			{page === 'checklists' && (
				<TitleWithTooltip
					title={translate('callFilters', language)}
					tooltipTitle={translate('callFilters', language)}
					theme="black"
					size="mdL"
				/>
			)}
			<div className={simpleListWithTitles ? classes.searchItemsFullWidth : classes.searchItems}>
				{activeCriterias.length > 0 ? (
					allCriterias &&
					sortedCriterias.map((criteriasObj, index) => (
						<div
							className={cn(getBlockClassName(criteriasObj.blockNumber, simpleListWithTitles || false))}
							key={criteriasObj.blockNumber}
						>
							{criteriasObj.blockNumber === 1 && page === 'callsFromReportCell' && (
								<div className={classes.searchTitleTop}>
									<div className={classes.searchTitleLeft}>
										<Typography className={classes.searchTitleTitle} variant="subtitle1">
											{blockTitle || translate('fragmentSearch', language)}
										</Typography>
										<TooltipCustom
											background="dark"
											title={translate('tooltipFragments', language)}
											placement="right"
											followCursor={false}
										>
											<div className={classes.questionWrapper}>
												<IconQuestion />
												<div className={classes.questionTitle}>
													{translate('howToSearch', language)}
												</div>
											</div>
										</TooltipCustom>
										{currentTemplate !== undefined && allTemplates !== undefined && (
											<>
												{/* шаблоны */}
												{!simpleListWithTitles && (
													<CriteriaTemplate
														height="400px"
														name="templatesCalls"
														title={translate('savedSearchTemp', language)}
														handleValueChange={
															onTemplateSelectValueChange as (event: any) => void
														}
														options={
															convertedTemplate as {
																value: any;
																label: string;
															}[]
														}
														currentTemplate={optionsCreatorSimpleTitle(currentTemplate)}
														allTemplates={allTemplates}
														onClearTemplateClick={onClearTemplateClick}
														dataTestTd="templatesCalls"
													/>
												)}
											</>
										)}
									</div>
								</div>
							)}
							{!simpleListWithTitles &&
								criteriasObj.blockNumber === 3 &&
								page === 'callsFromReportCell' && (
									<div className={classes.searchTitleTop}>
										<div className={classes.searchTitleLeft}>
											<Typography className={classes.searchTitleTitle} variant="subtitle1">
												{translate('callsParameters', language)}
											</Typography>
											<TooltipCustom
												background="dark"
												title={translate('tooltipParam', language)}
												placement="bottom"
												followCursor={false}
											>
												<div className={classes.questionWrapper}>
													<IconQuestion />
													<div className={classes.questionTitle}>
														{translate('whatIsIt', language)}
													</div>
												</div>
											</TooltipCustom>
										</div>
									</div>
								)}
							{simpleListWithTitles && criteriasObj.blockNumber === 3 && (
								<div className={classes.searchTitle}>
									<div className={classes.searchTitleLeft}>
										<Typography className={classes.searchTitleTitle} variant="subtitle1">
											{translate('callsParameters', language)}
										</Typography>
										<Hint
											title={translate('tooltipParam', language)}
											label={translate('whatIsIt', language)}
										/>
									</div>
								</div>
							)}
							{criteriasObj.blockNumber === 4 && (
								<div className={classes.searchTitle}>
									<div className={classes.searchTitleLeft}>
										<Typography className={classes.searchTitleTitle} variant="subtitle1">
											{translate('reportAddParameters', language)}
										</Typography>
										<Hint
											title={translate('tooltipTags', language)}
											label={translate('whatIsIt', language)}
											placement="right"
										/>
									</div>
								</div>
							)}
							<div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
								{criteriasObj.criterias.map((criteria) => {
									const compResult = allCriterias.filter((v) => v.key === criteria.key);
									return (
										<div className={cn(getBlockSearchClassName(criteria.block))} key={criteria.key}>
											<CriteriaItem
												title={criteria.title}
												criteriaFull={compResult[0]}
												criteriaCurrent={criteria}
												block={block as CriteriaBlockPage}
												handlerOnChange={handlerOnChange as HandlerOnChange}
												isHandlerFunction={isHandlerFunction}
												page={page}
											/>
										</div>
									);
								})}
								{Object.keys(criteriasObj.complexCriterias).length > 0 &&
									Object.keys(criteriasObj.complexCriterias).map((item) => (
										<CriteriaWithConditions
											key={criteriasObj.complexCriterias[item][0].key}
											criteriaKey={criteriasObj.complexCriterias[item][0].key}
											allCriterias={allCriterias}
											data={criteriasObj.complexCriterias[item]}
											block={block as CriteriaBlockPage}
											index={index}
											handlerOnChange={handlerOnChange as HandlerOnChange}
											isHandlerFunction={isHandlerFunction}
											page={page}
											onAddCondition={onAddCondition}
											onDeleteCondition={onDeleteCondition}
										/>
									))}
							</div>
						</div>
					))
				) : (
					<div>
						<Typography style={{ fontSize: '13px', marginTop: '15px' }}>
							{translate('searchEmpty', language)}
						</Typography>
					</div>
				)}
			</div>
		</div>
	);
};

export default CriteriaMultiList;
