import { useDispatch } from 'react-redux';
import { translate } from 'localizations';
import { searchSlice } from 'store';
import { LangType } from 'store/lang/lang.slice';
import { EnvsManager } from 'utils/enviroments';
import { ModeType } from 'store/search/search.types';
import { callsActions } from 'store/calls';
import { AccessRights } from 'store/user/user.types';

export interface ToggleButtonInterface {
	key: string;
	value: string;
	onClick: () => void;
	pathName: string;
	title: string;
	withSelect?: boolean;
	isForProd?: boolean;
}
const useToggleButtons = (language: LangType | string, mode: ModeType, accessRights: AccessRights | null) => {
	const dispatch = useDispatch();

	let toggleButtons: ToggleButtonInterface[] = [
		{
			key: 'calls',
			value: 'calls',
			onClick: () => {
				dispatch(searchSlice.actions.setMode('SEARCH'));
				if (mode === 'REPORTS') {
					dispatch(callsActions.setBaseCallsData(null));
					dispatch(callsActions.setSearchCallsHash(undefined));
					dispatch(callsActions.setFilterCallsHash(undefined));
				}
			},
			pathName: 'calls',
			title: translate('calls', language),
			isForProd: true,
		},
		{
			key: 'reports',
			value: 'reports',
			onClick: () => {},
			pathName: 'reports',
			title: translate('reports', language),
			withSelect: true,
			isForProd: true,
		},
		{
			key: 'tags',
			value: 'tags',
			onClick: () => {},
			pathName: 'tags',
			title: translate('markupRules', language),
			isForProd: true,
		},
		{
			key: 'notifications',
			value: 'notifications',
			onClick: () => {},
			pathName: 'notifications',
			title: translate('notifications', language),
			isForProd: !EnvsManager.isDev,
		},
		{
			key: 'deals',
			value: 'deals',
			onClick: () => {},
			pathName: 'deals',
			title: translate('deals', language),
			isForProd: true,
		},
		{
			key: 'settings',
			value: 'settings',
			onClick: () => {},
			pathName: 'settings',
			title: translate('settings', language),
			isForProd: true,
		},
	];

	// Если прав на сделки нет, убираем соответствующую кнопку
	if (!accessRights?.deals) {
		toggleButtons = toggleButtons.filter((btn) => btn.key !== 'deals');
	}

	return toggleButtons;
};

export default useToggleButtons;
