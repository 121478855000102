import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import variables from 'styles/variables.scss';
import { ErrorsArr } from './multiValuesSelect';

type SelectCustomPropsType = {
	width?: string;
	height?: string;
};
export const useMuiCustomSelectStyles = makeStyles<Theme, SelectCustomPropsType>((theme: Theme) => ({
	selectBox: {
		display: 'flex !important',
		alignItems: 'center',
		width: '100%',
	},
	selectMenuListInput: {
		width: '270px',
		margin: '16px 24px',
		outline: 'none',
		backgroundColor: variables.gray_2,
		border: `1px solid ${variables.gray_4}`,
		borderRadius: '5px',
		padding: '10px 30px 10px 12px',
		color: variables.gray_7,
		fontSize: '14px',
		position: 'relative',
	},
	selectMenuListInputIcon: {
		position: 'absolute',
		left: '262px',
		top: '23px',
		color: variables.gray_7,
		width: '11px',
	},
	selectOption: {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '5px 24px',
	},
	selectArrow: {
		marginRight: '10px',
	},
	selectArrowOnArrow: {
		marginBottom: '5px',
	},
	selectCheckBox: {
		'&.MuiCheckbox-root': {
			backgroundColor: `${variables.gray_4} !important`,
		},
	},
	selectTag: {
		height: '22px !important',
		cursor: 'pointer !important',
		fontFamily: 'Inter, sans-serif !important',
		border: '2px solid #E9ECEF !important',
		borderRadius: '5px !important',
		margin: '2.5px !important',
		padding: '0 5px',
		backgroundColor: '#E9ECEF !important',
		color: '#000000 !important',
		fontSize: '12px',
		display: 'flex',
		alignItems: 'center',
	},
	selectSelectBox: {
		width: '100%',
		position: 'relative',
		minWidth: (props) => (props.width ? props.width : 'auto'),
	},
	selectPlaceholder: {
		position: 'absolute',
		left: '11px !important',
		top: '9px !important',
	},
	input: (provided: any) => ({
		...provided,
		paddingTop: '0px !important',
	}),
}));

export function selectCustomStylesCreator(props: {
	height?: string;
	backgroundColor?: string;
	border?: string;
	color?: string;
	menuWidth?: string;
	borderColor?: string;
	disabled?: boolean;
	errorsArr?: ErrorsArr;
}) {
	return {
		container: (provider: any) => ({
			...provider,
			width: '100%',
		}),
		menu: (provided: any) => ({
			...provided,
			width: props.menuWidth || '322px',
			height: '400px !important',
			overflow: 'hidden',
			overflowY: 'auto',
			zIndex: '1000',
			'&::-webkit-scrollbar': {
				width: '4px',
				backgroundColor: '#f1f1f1',
				outline: 'none',
			},
			'&::-webkit-scrollbar-thumb': {
				background: variables.gray_6,
				height: '50px',
				borderRadius: '10px',
			},
			'&::-webkit-scrollbar-thumb:hover': {
				background: '#9298A1',
			},
		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			padding: '10px 24px',
			fontFamily: 'Inter, sans-serif',
			fontSize: '14px',
			textAlign: 'start',
			backgroundColor: state.isFocused ? '#F8FAFC' : variables.gray_1,
			color: state.isFocused ? '#722ED1' : '#000',
			whiteSpace: 'normal',
			wordBreak: 'break-all',
			overflowWrap: 'break-word',
			'&:hover': {
				backgroundColor: '#F8FAFC',
				color: '#722ED1',
			},
		}),
		control: (provided: any, state: any) => ({
			...provided,
			border: `1px solid ${props.borderColor ?? variables.gray_4}`,
			boxShadow: 'none',
			'&:hover': {
				borderColor: variables.gray_4,
			},
			minHeight: props.height ? props.height : '36px',
			minWidth: '70px',
			borderRadius: '5px',
			backgroundColor: variables.gray_2,
		}),
		placeholder: (provided: any) => ({
			...provided,
			fontSize: '14px',
			color: variables.gray_6,
			fontFamily: 'Inter, sans-serif',
			position: 'absolute',
			top: '3px',
		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			'& svg': {
				width: '16px',
				height: '18px',
			},
		}),
		indicatorSeparator: (provided: any) => ({
			display: 'none',
		}),
		multiValue: (provided: any, state: any) => {
			const val = state.data.value;
			const hasError = props.errorsArr && props.errorsArr[val] === true;
			if (hasError) {
				return {
					...provided,
					height: '24px !important',
					fontFamily: 'Inter, sans-serif !important',
					fontSize: '14px !important',
					borderRadius: '5px !important',
					margin: '2.5px !important',
					maxWidth: '190px !important',
					backgroundColor: '#FFCCC7 !important', // Error color
					color: '#CF1322 !important',
					border: props.border ? `1px solid ${props.border} !important` : `1px solid #FF7875 !important`,
					'& div': {
						color: '#CF1322 !important',
					},
				};
			}
			return {
				...provided,
				height: '24px !important',
				fontFamily: 'Inter, sans-serif !important',
				fontSize: '14px !important',
				borderRadius: '5px !important',
				margin: '2.5px !important',
				maxWidth: '190px !important',
				backgroundColor: props.backgroundColor ? props.backgroundColor : `${variables.purple_2} !important`,
				color: props.color ? props.color : `${variables.purple_7} !important`,
				border: props.border
					? `1px solid ${props.border} !important`
					: `1px solid ${variables.purple_4} !important`,
				'& div': {
					color: props.color ? props.color : `${variables.purple_7} !important`,
					overflow: 'hidden',
				},
				'& div[role="button"]': {
					minWidth: '24px',
				},
			};
		},

		MultiValueGeneric: (provided: any) => ({
			...provided,
			color: props.color ? props.color : `${variables.purple_7} !important`,
		}),

		multiValueRemove: (provided: any, state: any) => {
			const val = state.data.value;
			const hasError = props.errorsArr && props.errorsArr[val] === true;
			if (hasError) {
				return {
					...provided,
					color: '#CF1322 !important',
					'&:hover': {
						backgroundColor: '#FF4D4F !important',
						color: '#FFF !important',
					},
				};
			}
			return {
				...provided,
				backgroundColor: props.backgroundColor ? props.backgroundColor : `${variables.purple_2} !important`,
				'& svg': {
					width: '15px',
					height: '16px',
				},
				'&:hover': {
					backgroundColor: props.backgroundColor ? props.backgroundColor : `${variables.purple_2} !important`,
				},
			};
		},
		valueContainer: (provided: any) => ({
			...provided,
			display: 'flex',
			padding: '0',
			paddingLeft: '5px',
		}),
		input: (provided: any) => ({
			...provided,
			paddingTop: '0px !important',
			cursor: props.disabled ? 'not-allowed' : 'pointer',
		}),
	};
}

export const getMultiSelectStyles = makeStyles({
	selectWrapperComplex: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		marginBottom: '2px',
	},
	selectWrapperMultiString: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	selectTitleComplex: {
		fontSize: '14px !important',
		color: `${variables.gray_7} !important`,
		marginBottom: '4px !important',
	},
	selectTitleMultiString: {
		fontSize: '13px !important',
		fontWeight: '700 !important',
		color: `${variables.gray_7} !important`,
		marginBottom: '4px !important',
	},
	selectListWrapper: {
		height: '380px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
