import React, { FC, memo, PropsWithChildren } from 'react';
import { components, MultiValueGenericProps } from 'react-select';
import { getGlobalStyles } from 'styles';
import { HighlightedLabel } from 'components/common';
import { useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';
import { OptionType } from './multiValuesSelect';
import TooltipCustom from 'components/common/Tooltip/Tooltip';

interface ITagOption {
	tag: string;
	tagValue: string;
	search: string;
	id: string;
}

interface IChecklistOption {
	search: string;
	title: string;
	question?: string;
	answer?: string;
	questionId?: string;
}

// элемент тела селекта
export const CustomOption = (props: PropsWithChildren<any>) => {
	const { children, label, isSelected } = props;
	if (children.length > 0) {
		return (
			<components.Option {...props}>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<label>{label}</label>
					<input
						style={{ backgroundColor: '#722ED1 !important' }}
						type="checkbox"
						checked={isSelected}
						onChange={() => null}
					/>
				</div>
			</components.Option>
		);
	}
	return null;
};

export const CustomMultiValueTooltip = (props: MultiValueGenericProps<any>) => {
	return (
		<TooltipCustom title={props.data.label} background="dark">
			<components.MultiValueLabel {...props} />
		</TooltipCustom>
	);
};

export const TagOption: FC<ITagOption> = ({ tag, tagValue, search, id }) => {
	const { purpleTag } = getGlobalStyles();

	return (
		<div style={{ color: '#531DAB' }}>
			<span className={purpleTag}>{tag}</span>
			{tagValue && (
				<span>
					&nbsp;&rarr;&nbsp;
					<HighlightedLabel text={tagValue} highlight={search} id={id} />
				</span>
			)}
		</div>
	);
};

export const MultiValue = ({ index, getValue, ...props }: PropsWithChildren<any>) => {
	const { language } = useAppSelector((state) => state.lang);
	const maxToShow = 5;
	const overflow = getValue()
		.slice(maxToShow)
		.map((x: OptionType) => x);
	const overflowMessage = overflow.length > 0 && `${translate('more', language)} ${overflow.length}`;
	const placeholder = index === maxToShow ? overflowMessage : null;

	return index < maxToShow ? <components.MultiValue {...props} /> : <span>{placeholder}</span>;
};

export const ComplexTagOption = (props: PropsWithChildren<any>) => {
	const {
		children,
		data: { value, search, id },
	} = props;
	if (children && children.length > 0) {
		const { name: tag, value: tagValue } = JSON.parse(value);
		return (
			<components.Option {...props}>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<TagOption tag={tag} tagValue={tagValue} search={search} id={id} />
				</div>
			</components.Option>
		);
	}
	return null;
};

// элемент поиска по комментариям
export const ComplexCommentOption = (props: PropsWithChildren<any>) => {
	const {
		children,
		data: { value, label },
	} = props;
	if (children && children.length > 0) {
		return (
			<components.Option {...props}>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<div>{label}</div>
				</div>
			</components.Option>
		);
	}
	return null;
};

// цепочка с названием чеклиста, его вопросом и ответом
export const ChecklistOption: FC<IChecklistOption> = ({ title, search, questionId, question, answer }) => {
	const { purpleTag } = getGlobalStyles();

	return (
		<div style={{ color: '#531DAB' }}>
			<span className={purpleTag}>{title}</span>
			{question && questionId && (
				<span>
					&nbsp;&rarr;&nbsp;
					<HighlightedLabel text={question} highlight={search} id={questionId} />
				</span>
			)}
			{answer && questionId && (
				<span>
					&nbsp;&rarr;&nbsp;
					<HighlightedLabel text={answer} highlight={search} id={questionId} />
				</span>
			)}
		</div>
	);
};

// элемент селекта для поиска по чеклистам
export const ComplexChecklistOption = (props: PropsWithChildren<any>) => {
	const {
		children,
		data: { value, search },
	} = props;
	if (children && children.length > 0) {
		const { checklist_title, question_id, question_text, answer_text } = JSON.parse(value);
		return (
			<components.Option {...props}>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<ChecklistOption
						search={search}
						title={checklist_title}
						question={question_text}
						questionId={question_id}
						answer={answer_text}
					/>
				</div>
			</components.Option>
		);
	}
	return null;
};

// элементы тела селекта
export const CustomMenuList: FC = memo((props: PropsWithChildren<any>) => <div>{props.children}</div>);
