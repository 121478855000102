/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import LogoImg from 'assets/images/logo.svg';
import { RootState } from 'store/store';
import { translate } from 'localizations';
import { removeAuthToken } from 'store/user/auth.thunks';
import { useAppSelector } from 'hooks/redux';
import { searchSlice } from 'store';
import { LabelSelect, OldInterfaceLink, Tooltip } from 'components/common';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import BellSelect from 'components/RealtimeTasks/BellSelect';
import { PersonSvg } from 'components/Icons/PersonSvg';
import { getAllReports } from 'store/reports/actions';
import { useStyles } from './Header.jss';
import ToggleButtons from '../ToggleButtons/ToggleButtons';
import useToggleButtons from './hooks/useToggleButtons';
import { useSnackbar } from 'notistack';
import { getBaseCallsData } from 'store/calls/actions';
import { exportsActions } from 'pages/Calls/components/CallsHeader/const';
import { getActionFiles, getTaskStatus, tasksSlice, TaskType } from 'store/tasks/tasks.slice';
import { createSnackbarOptions } from '../Snackbar/Snackbar';
import { useCurrentUser } from 'hooks';
import LangSelector from 'features/LangSelector/LangSelector';
import { LangType } from 'store/lang/lang.slice';
import { RouteParams } from 'pages/Settings/types';
import queryString from 'query-string';
import { useActionsFunctions } from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/hooks/useActionsFunctions';
import { callsActions } from 'store/calls';

const Header: React.FC = () => {
	const { lang } = useParams<RouteParams>();
	const { language } = useAppSelector((state) => state.lang);

	const currentLang = lang || language;

	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useAppSelector((state) => state.user.user);
	const childUser = useAppSelector((state) => state.user.childUser);

	const isAuth = useAppSelector((state) => state.user.isAuth);
	const pathArray = history.location.pathname.split('/');
	const path = pathArray[3];
	const accessRights = useAppSelector((state) => state.user.accessRights);
	const mode = useAppSelector((state) => state.search.calls.mode);
	const userPathName = childUser ? childUser.id : user ? user.id : '_';

	const toggleButtons = useToggleButtons(currentLang, mode, accessRights);

	const tasks = useAppSelector((state) => state.tasks.realtimeTasks);

	const { enqueueSnackbar } = useSnackbar();
	const sort = useAppSelector((state: RootState) => state.calls.sort);
	const search_filter_hash = useAppSelector((state) => state.search.reports.search_filter_hash);
	const period = useAppSelector((state) => state.search.calls.period);

	const searchObject = queryString.parse(history.location.search.slice(1)); // @ts-ignore
	const reportCellCallHashKey = searchObject.reportCellCallHashKey;

	const currentUser = useCurrentUser();
	const { loggedUser } = useAppSelector((state) => state.user);

	// роутинг
	const [alignment, setAlignment] = useState('');

	const [pageCall, setPageCall] = useState<string | null>(null);
	const { updateCall } = useActionsFunctions(null);
	const updateCallHandler = async () => {
		if (pageCall) {
			await updateCall(pageCall);
		}
	};
	const listeningToPages = (page: string, pageCall: string | null) => {
		setAlignment(page);
		setPageCall(pageCall);
	};

	const handleRouteChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		setAlignment(newAlignment);
	};

	useEffect(() => {
		const hasReports = !['admin', 'manager'].includes(currentUser?.role as string) && accessRights?.report;
		if (hasReports) {
			const fetchAllReports = async () => {
				await dispatch(getAllReports({}));
			};
			fetchAllReports();
		}
	}, [currentUser?.id]);

	useEffect(() => {
		const pageSetter = (location: any) => {
			const page = location.pathname.split('/')[3];
			if (page === 'dictionaries' || page === 'checklists' || page === 'gpt') listeningToPages('tags', null);
			else if (page === 'report') listeningToPages('reports', null);
			else if (page === 'call')
				listeningToPages('call', location.search && location.search.split('=')[1].split('&')[0]);
			else listeningToPages(page, null);
		};
		history.listen((location) => {
			pageSetter(location);
		});
		pageSetter(history.location);
	}, [history.location]);

	const goToProfile = () => {
		history.push(`/${currentLang}/${user ? user.id : '_'}/settings?settingsParam=usersProfile`);
	};

	const logout = () => {
		dispatch(removeAuthToken());
		dispatch(searchSlice.actions.searchReset());
		history.push(`/${currentLang}/auth`);
		window.location.reload();
	};

	const login = () => {
		history.push(`/${currentLang}/auth`);
	};

	const onLogoClick = (): void => {
		history.push(`/${currentLang}/${userPathName}/calls`);
		window.location.reload();
	};

	// задачи
	const progressTasksForMode = async (task: TaskType) => {
		if (task.action && exportsActions.includes(task.action)) {
			// скачивание звонков (экспорт аудио, экспорт расшифровки, экспорт звонков)
			await dispatch(getActionFiles({ taskId: task.task_id, action: task.action }));
		} else {
			// все остальные действия
			if (mode === 'REPORTS') {
				await dispatch(
					getBaseCallsData({
						page: 1,
						sort: sort.sort,
						sortDesc: sort.sortDesc,
						report_cell_call_hash_key: reportCellCallHashKey as string,
						period: null,
					}),
				);
			} else if (pageCall) {
				// для страницы звонка
				await updateCallHandler().then(() => {
					dispatch(callsActions.setCallTranslated(null));
				});
			} else {
				await dispatch(getBaseCallsData({ page: 1, sort: sort.sort, sortDesc: sort.sortDesc, period }));
			}
		}
	};

	const intervalsRef = useRef<{ [key: string]: NodeJS.Timeout }>({});

	const realTimeTasksHandler = (task: TaskType, index: number) => {
		// Добавил условие для обработки exportsActions с "SUCCESS"
		if (
			(!['SUCCESS', 'FAILURE'].includes(task.status) && !task.startProgress) ||
			(task.action && exportsActions.includes(task.action) && task.status === 'SUCCESS')
		) {
			if (intervalsRef.current[task.task_id]) {
				// Интервал уже существует, не создаем новый
				return;
			}

			dispatch(tasksSlice.actions.replaceRealtimeTask({ task: { ...task, startProgress: true }, index }));

			const timer = setInterval(async () => {
				const taskData = await dispatch(getTaskStatus(task.task_id)); // @ts-ignore
				const newTask = taskData.payload;

				if (newTask) {
					dispatch(
						tasksSlice.actions.replaceRealtimeTask({
							task: { ...newTask, startProgress: true },
							index,
						}),
					);
				} else {
					clearInterval(timer);
					delete intervalsRef.current[task.task_id];
					enqueueSnackbar(
						null,
						createSnackbarOptions({
							time: 2000,
							text: translate('taskCanceled', currentLang),
							type: 'info',
						}),
					);
				}

				if (!['PROGRESS', 'PENDING'].includes(newTask.status)) {
					clearInterval(timer);
					delete intervalsRef.current[task.task_id];
					progressTasksForMode(task);

					dispatch(tasksSlice.actions.removeRealtimeTask(index));
					enqueueSnackbar(
						null,
						createSnackbarOptions({
							time: 2000,
							text: translate('taskComplete', currentLang),
							type: 'success',
						}),
					);
				}
			}, 2000);

			intervalsRef.current[task.task_id] = timer;
		}
	};

	useEffect(() => {
		const taskIds = tasks.map((task) => task.task_id);

		// Очищаем интервалы для задач, которых больше нет
		Object.keys(intervalsRef.current).forEach((taskId) => {
			if (!taskIds.includes(taskId)) {
				clearInterval(intervalsRef.current[taskId]);
				delete intervalsRef.current[taskId];
			}
		});

		// Обрабатываем новые или обновленные задачи
		tasks.forEach((task, index) => {
			realTimeTasksHandler(task, index);
		});
	}, [tasks]);

	const isAmoWidget = JSON.parse(sessionStorage.getItem('isAmoWidget') || 'false');

	return (
		<div className={classes.headerWrapper}>
			<div className={classes.headerInner}>
				<div className={classes.headerLeftBlock}>
					{/* Логотип */}
					<div style={{ cursor: 'pointer' }} onClick={onLogoClick} className={classes.headerIconWrapper}>
						<img className={classes.headerIcon} height={55} src={LogoImg} alt="Imot.io" />
					</div>
					{/* Навигация */}
					{isAuth && (
						<ToggleButtons
							alignment={alignment}
							handleRouteChange={handleRouteChange}
							toggleButtons={toggleButtons}
							language={currentLang}
							userPathName={userPathName}
							accessRights={accessRights}
						/>
					)}
				</div>
				<div className={classes.headerRightBlock}>
					<div style={{ marginTop: '2px', display: 'flex', alignItems: 'center' }}>
						{isAuth && (
							<>
								{/* история задач */}
								<div style={{ marginLeft: '5px' }}>
									<BellSelect />
								</div>
								{/* старый интерфейс */}
								<div style={{ marginLeft: '5px' }}>
									{!window.location.host.includes('ppr.imot.io') && <OldInterfaceLink />}
								</div>
							</>
						)}
						{/* логин/логаут */}
						<div style={{ marginLeft: '5px' }}>
							{isAuth && (
								<Tooltip title={translate('profile', currentLang)} placement="bottom">
									<div style={{ marginRight: '10px', cursor: 'pointer' }}>
										{!isAmoWidget ? (
											<LabelSelect
												value={[]}
												options={[
													{ label: translate('profile', currentLang), value: 'profile' },
													{ label: translate('logout', currentLang), value: 'logout' },
												]}
												notPlusMinus
												valueHandler={(values: OptionType[]) => {
													if (values[0].value === 'logout') logout();
													else if (values[0].value === 'profile') goToProfile();
												}}
												customLabel={
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<IconButton size="small">
															<PersonSvg fill="#A3AEBE" />
														</IconButton>
													</div>
												}
											/>
										) : (
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<IconButton size="small" onClick={() => goToProfile()}>
													<PersonSvg fill="#A3AEBE" />
												</IconButton>
											</div>
										)}
									</div>
								</Tooltip>
							)}
						</div>
						{/* смена языка */}
						{isAuth && (
							<LangSelector
								user={user}
								childUser={childUser}
								loggedUser={loggedUser}
								path={path}
								language={currentLang as LangType}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
